import check from 'check-types'
import { isBitSet } from "utils/number";

const getLogoUrl = (clientSettingsData) => clientSettingsData.get('logoUrl');
const getAppBarLogoUrl = (clientSettingsData) => clientSettingsData.get('appBarLogoUrl');
const getPrimaryColour = (clientSettingsData) => clientSettingsData.get('primaryColour');
const getSecondaryColour = (clientSettingsData) => clientSettingsData.get('secondaryColour');
const getProctoringMode = (clientSettingsData) => clientSettingsData.get('proctoringMode');
const getProctoringType = (clientSettingsData) => clientSettingsData.get('proctoringType');
const getPreviewMode = (clientSettingsData) => clientSettingsData.get('previewMode');
const getProctoringSessionID = (clientSettingsData) => clientSettingsData.get('proctoringSessionID');
const getDemoMode = (clientSettingsData) => clientSettingsData.get('demoMode') === '1';

const getCanDownloadResult = (clientSettingsData) => {
	//clientSettingsData.get('downloadResult') === '1';
	return clientSettingsData.get('canDownloadResult');
}

const getIsDownloadablePracticeExam = (clientSettingsData) => {
	//return true;
	return getDemoMode(clientSettingsData) && getCanDownloadResult(clientSettingsData);
};

const getThemesData = (clientSettingsData) => {
	return clientSettingsData.get('themes');
}

const getUploadFileTypesAllowed = (clientSettingsData) => (
	clientSettingsData.get('uploadFileTypes')
);

const shouldNotifyImmediatelyOnLostConnection = (clientSettingsData) => (
	!!clientSettingsData.get('notifyImmediatelyOnLostConnection')
);

const getCalculatorEvaluationMode = (clientSettingsData) => {
	return clientSettingsData.get('calculatorEvaluationMode')
}

const getConfirmDobMode = (clientSettingsData) => {
	return clientSettingsData.get('confirmDobMode')
}

const getConfirmLearnerIdentifierMode = (clientSettingsData) => {
	return clientSettingsData.get('confirmLearnerIdentifierMode');
}

const getHideSectionNamesInPlayer = (clientSettingsData) => {
	return clientSettingsData.get('hideSectionNamesInPlayer')
}

const getRememberScenario = (clientSettingsData) => {
	return clientSettingsData.get('rememberScenario')
}

const getEnableForgottenPassword = (clientSettingsData) => {
	return clientSettingsData.get('enableForgottenPassword')
}

const getLegacySingleSignon = (clientSettingsData) => {
	return clientSettingsData.get('legacySingleSignon')
}

const getAdminUrl = (clientSettingsData) => {
	return clientSettingsData.get('adminUrl')
}

const getUploadFileTypes = (clientSettingsData) => {
	return clientSettingsData.get('uploadFileTypes')
}

const getResultFeedbackMask = (clientSettingsData) => {
	return clientSettingsData.get('resultFeedbackMask')
}

const getIntegrityAdvocateAppId = (clientSettingsData) => {
	return clientSettingsData.get('integrityAdvocateAppId')
}

const getOrgFullScreenExam = (clientSettingsData) => {
	return clientSettingsData.get('fullScreenExam')
}

const getCalculatorPercentageButton=(clientSettingsData) => {
	const flag=clientSettingsData.get('calculatorExtraButtonsFlag');
	return check.integer(flag)?isBitSet(flag, 1):0;
}

const getCalculatorSquareButton=(clientSettingsData) => {
	const flag=clientSettingsData.get('calculatorExtraButtonsFlag');
	return check.integer(flag)?isBitSet(flag, 2):0;
}

const getShowQuestionIdInPlayer=(clientSettingsData)=>{
	return clientSettingsData.get('showQuestionIdInPlayer')
}


export {
	getLogoUrl,
	getThemesData,
	getAppBarLogoUrl,
	getPrimaryColour,
	getSecondaryColour,
	getUploadFileTypesAllowed,
	getCalculatorEvaluationMode,
	getProctoringMode,
	getProctoringType,
	getPreviewMode,
	getDemoMode,
	getConfirmLearnerIdentifierMode,
	getCanDownloadResult,
	getIsDownloadablePracticeExam,	
	shouldNotifyImmediatelyOnLostConnection,
	getConfirmDobMode,
	getHideSectionNamesInPlayer,
	getRememberScenario,
	getEnableForgottenPassword,
	getLegacySingleSignon,
	getAdminUrl,
	getUploadFileTypes,
	getResultFeedbackMask,
	getIntegrityAdvocateAppId,
	getProctoringSessionID,
	getOrgFullScreenExam,
	getCalculatorPercentageButton,
	getCalculatorSquareButton,
	getShowQuestionIdInPlayer
}