const CREATE_SETTINGS = "[settings][client]create.settings";
const REMOVE_SETTINGS = "[settings][client]remove.settings";
const LOAD_SETTINGS = "[settings][client]load.settings";
const SET_NOTIFY_IMMEDIATELY_ON_LOST_CONNECTION =
    "[settings][client]set.n.i.o.l.c";
const SET_CALCULATOR_EVALUATION_MODE =
    "[settings][client]set.calculator.evaluation.mode";
const SET_CONFIRM_DOB_MODE = "[settings][client]set.confirm.dob.mode";
const SET_CONFIRM_LEARNER_IDENTIFIER_MODE = "[settings][client]set.confirm.learner.identifier.mode";
const SET_HIDE_SECTION_NAMES_IN_PLAYER = "[settings][client]set.hide.section.name.in.player";
const SET_REMEMBER_SCENARIO = "[settings][client]set.remember.scenario";
const SET_UPLOAD_FILE_TYPES = "[settings][client]set.upload_file_types";
const SET_RESULT_FEEDBACK_MASK = "[settings][client]set.result.feedback.mask";
const SET_INTEGRITY_ADVOCATE_APP_ID = "[settings][client]set.integrity.advocate.app.id";
const SET_ORG_FULL_SCREEN_EXAM = "[settings][client]set.org.full.screen.exam";
const SET_ORG_CALCULATOR_EXTRA_BUTTONS_FLAG = "[settings][client]set.org.calculator.extra.buttons.flag";
const SET_ORG_SHOW_QUESTION_ID_IN_PLAYER = "[settings][client]set.org.show_question.id.in.player";


export {
    CREATE_SETTINGS,
    REMOVE_SETTINGS,
    LOAD_SETTINGS,
    SET_NOTIFY_IMMEDIATELY_ON_LOST_CONNECTION,
    SET_CALCULATOR_EVALUATION_MODE,
    SET_CONFIRM_DOB_MODE,
    SET_CONFIRM_LEARNER_IDENTIFIER_MODE,
    SET_HIDE_SECTION_NAMES_IN_PLAYER,
    SET_REMEMBER_SCENARIO,
    SET_UPLOAD_FILE_TYPES,
    SET_RESULT_FEEDBACK_MASK,
    SET_INTEGRITY_ADVOCATE_APP_ID,
    SET_ORG_FULL_SCREEN_EXAM,
    SET_ORG_CALCULATOR_EXTRA_BUTTONS_FLAG,
    SET_ORG_SHOW_QUESTION_ID_IN_PLAYER    
};
