
// utils
import {api} from '../api'
import {ENDPOINTS} from '../constants'


const getCalculatorEvaluationMode = (orgId) =>
{
	const payload = {
		orgID: orgId,
		setting: "SimpleCalculatorEvaluation"
	}

	return api.get(ENDPOINTS.SETTING, payload);
}

const getConfirmDobMode = (orgId) =>
{
	const payload = {
		orgID: orgId,
		setting: "ConfirmLearnerDOB"
	}

	return api.get(ENDPOINTS.SETTING, payload);
}

const getConfirmLearnerIdentifierMode = (orgId) =>
{
	const payload = {
		orgID: orgId,
		setting: "ConfirmLearnerIdentifier"
	}

	return api.get(ENDPOINTS.SETTING, payload);
}

const getNotifyImmediatelyOnConnectionLoss = (orgId) =>
{
	const payload = {
		orgID: orgId,
		setting: "NotifyLearnerImmediatelyOnConnectionLoss"
	}

	return api.get(ENDPOINTS.SETTING, payload);
}

const getHideSectionNamesInPlayer = (orgId) => {
	const payload = {
		orgID: orgId,
		setting: "HideSectionNamesInPlayer"
	}

	return api.get(ENDPOINTS.SETTING, payload);	
}

const getRememberScenario = (orgId) => {
	const payload = {
		orgID: orgId,
		setting: "xams6_RememberScenario"
	}

	return api.get(ENDPOINTS.SETTING, payload);	
}

const getUploadFileTypes = (orgId) => {
	const payload = {
		orgID: orgId,
		setting: "AllowedAssignmentUploadFileTypes"
	}

	return api.get(ENDPOINTS.SETTING, payload);	
}

const getResultFeedbackMask = (orgId) =>
{
	const payload = {
		orgID: orgId,
		setting: 'ResultFeedbackMask'
	}

	return api.get(ENDPOINTS.SETTING, payload);
}

const getIntegrityAdvocateAppID = (orgId) =>
{
	const payload = {
		orgID: orgId,
		setting: 'IntegrityAdvocate-AppID'
	}

	return api.get(ENDPOINTS.SETTING, payload);
}

const getProctorioLoginlessMode = (orgId) =>
{
	const payload = {
		orgID: orgId,
		setting: 'Proctorio-LoginlessMode'
	}

	return api.get(ENDPOINTS.SETTING, payload);
}

const getOrgFullScreenExam = (orgId) => {
	const payload = {
		orgID: orgId,
		setting: "FullScreenExamUIv6"
	}

	return api.get(ENDPOINTS.SETTING, payload);	
}

const getCalculatorExtraButtonsFlag= (orgId) => {
	const payload = {
		orgID: orgId,
		setting: 'CalculatorExtraButtonsFlag'
	}

    return api.get(ENDPOINTS.SETTING, payload);	
}

const getShowQuestionIDInPlayer = (orgId) => {
	const payload = {
		orgID: orgId,
		setting: "ShowQuestionIDInPlayer"
	}

	return api.get(ENDPOINTS.SETTING, payload);	
}


const settingsApi = {
	getCalculatorEvaluationMode,
	getNotifyImmediatelyOnConnectionLoss,
	getConfirmDobMode,
	getConfirmLearnerIdentifierMode,
	getHideSectionNamesInPlayer,
	getRememberScenario,
	getUploadFileTypes,
	getResultFeedbackMask,
	getIntegrityAdvocateAppID,
	getProctorioLoginlessMode,
	getOrgFullScreenExam,
	getCalculatorExtraButtonsFlag,
	getShowQuestionIDInPlayer
}

Object.freeze(settingsApi);


export {settingsApi};