
// npm
import React from 'react'
import PropTypes from 'prop-types'

// material-ui
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControl from '@material-ui/core/FormControl'
import withStyles from '@material-ui/core/styles/withStyles'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import {MaterialText} from 'components/presentation/material-text'

// react
import {DisableAnsweringContext} from 'components/pages/exam/disabled-context'


const getOptions = (currentValue, {options}, disabled, classes) => {
	const radioClasses = {root: classes.root, checked: classes.checked};

	const getLabelNode = (label) => (
		<MaterialText className={classes.text}>
			{label}
		</MaterialText>
	);

	const getRadioButton = (checked) => (
		<Radio classes={radioClasses} disabled={disabled} checked={checked}/>
	);


	return options.map(({value, label}, index) => {
		const key = `${label}${index}`;
		const labelNode = getLabelNode(label);
		const control = getRadioButton(value === currentValue);

		return (
            <FormControlLabel
                key={key}
                value={value}
                label={labelNode}
                control={control}
                classes={{ root: classes.formControlLabel }}
            />
        );
	})
}

const getOptionGroup = (props, disabled) => {
	const onChange = disabled ? undefined : props.onChange;
	const {value, formData} = props;

	return (
		<RadioGroup onChange={onChange} value={value}>
			{getOptions(value, formData, disabled, props.classes)}
		</RadioGroup>
	);
}

let McqAnswerForm = (props) => (
	<FormControl component="fieldset">
		<DisableAnsweringContext.Consumer>
			{value => getOptionGroup(props, value)}
		</DisableAnsweringContext.Consumer>
	</FormControl>
)


// TEMP

const styles = ({palette, spacing}) => ({
	root: {
		color: palette.background.contrastText,
		paddingTop: 0,
		paddingBottom: 0
	},
	text: {
		color: palette.background.contrastText,
		paddingTop:"2px"
	},
	checked: {
		color: palette.primary.main + "!important"
	},
	formControlLabel: {
		alignItems: 'flex-start', // Align radio buttons to the top
		paddingBottom:spacing.unit*2
	},
})

McqAnswerForm = withStyles(styles)(McqAnswerForm);

// ---

McqAnswerForm.propTypes = {
	value: PropTypes.string,
	onChange: PropTypes.func.isRequired,
	formData: PropTypes.shape({
		options: PropTypes.arrayOf(PropTypes.shape({
			value: PropTypes.string.isRequired,
			label: PropTypes.string.isRequired
		})).isRequired
	}).isRequired
}


export {McqAnswerForm}