import React, { Component } from "react";
import { connect } from "react-redux";

import { getScheduleUserId } from "redux/reducers/schedules/schedule/selectors";
import { getExamData, getSchedulesData } from "redux/reducers/selectors";
import { getScheduleDataByExamGuid } from "redux/reducers/schedules/selectors";
import { getGuid } from "redux/reducers/exam/selectors";
import { getSettingsData } from "redux/reducers/selectors";
import { getClientSettingsData } from "redux/reducers/settings/selectors";
import { getShowQuestionIdInPlayer } from "redux/reducers/settings/client/selectors";

import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";

class ExamIdMarker extends Component {
    state = {};
    render() {
        const { classes, show, scheduleUserId } = this.props;

        if (scheduleUserId && show) {

            return (
                <div className={classes.root}>
                    <Typography variant="caption">{scheduleUserId}</Typography>
                </div>
            );
        }
        return null;
    }
}

const styles = ({ spacing }) => {
    return {
        root: {
            display: "flex",
            justifyContent: "flex-start",
            paddingLeft: spacing.unit * 2,
            fontSize: ".75em",
            marginTop: "-12px",
            marginBottom: "-14px",
        },
    };
};

ExamIdMarker = withStyles(styles)(ExamIdMarker);

const mapStoreToProps = (store) => {
    const examData = getExamData(store);
    const examGuid = getGuid(examData);

    const schedulesData = getSchedulesData(store);
    const scheduleData = getScheduleDataByExamGuid(schedulesData)(examGuid);

    const settingsData = getSettingsData(store);
    const clientSettingsData = getClientSettingsData(settingsData);

    return {
        scheduleUserId: getScheduleUserId(scheduleData),
        show: getShowQuestionIdInPlayer(clientSettingsData),
    };
};

ExamIdMarker = connect(mapStoreToProps)(ExamIdMarker);

export { ExamIdMarker };
