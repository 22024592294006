import * as Immutable from 'immutable'
import * as ACTION_TYPES from './action-types'
import * as SCHEDULES_ACTION_TYPES from '../action-types'


const SCHEDULE_REDUCER = (state=Immutable.Map(), action) =>
{
	switch (action.type)
	{
		case SCHEDULES_ACTION_TYPES.ADD_SCHEDULE:{
			return Immutable.Map({
				guid: action.guid,
				info: action.info,
				examName: action.examName,
				examGuid: action.objectGUID,
				examTypeGuid: action.objectTypeGUID,
				type: action.scheduleType,
				startDt: action.startDateTime,
				startDtFmt: action.formattedStartDateTime,
				completeDt: action.completedDateTime,
				completeDtFmt: action.formattedCompletedDateTime,
				commenced: action.commenced,
				completed: action.completed,
				practice: action.practice,
				version: action.version,
				requiresPassword: action.requiresPassword,
				proctorProvider: action.proctorProvider,
				proctoringSessionID: action.proctoringSessionID,
				objectID: action.objectID,
				qualificationID: action.qualificationID,
				cantStartMessage: "",
				practiceExtraTime: action.practiceExtraTime,
				scheduleId: action.id,
				scheduleUserId: action.scheduleUserID				
			});
		}
		case ACTION_TYPES.SET_COMMENCED:
			return state.set('commenced', true);
		case ACTION_TYPES.SET_COMPLETED:
			return state.set('completed', true);
		case ACTION_TYPES.SET_CANT_START_MESSAGE:
			return state.set('cantStartMessage', action.message);
		default:
			return state;
	}
}

export {SCHEDULE_REDUCER}